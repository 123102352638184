<template>
    <div v-if="is_user">
        <div>
            <div>
                <h2 class="ml-2">{{ $t('tier_admin.user') }}:</h2>
            </div>
            <div class="box">
                <div class="row d-flex justify-content-between mx-2">
                    <div>
                        <h3>{{ tiers.tiers_mail }}</h3>
                        <h6>{{ $t('tier_admin.date_validite') }}: {{ user.licence_date_of_validity }}</h6>
                    </div>
                    <button class="btn btn-primary" type="button" @click.prevent="launchAutoLog(tiers.tiers_mail)">{{ $t('global.autolog_connect') }}</button>
                </div>
                <div v-if="!user_loading">
                    <div class="my-4">
                        <h5> {{ $t('tier_admin.gamme') }}:</h5>
                        <span> {{ user.gamme_label }}</span>
                    </div>
                    <div class="my-4">
                        <h5> {{ $t('tier_admin.options') }}:</h5>
                        <span v-for="option, index in user.licences.main_licence.options" :key="index"> {{ option.option_label }} <span v-if="index != user.licences.main_licence.options.length - 1"> // </span></span>
                    </div>
                    <div class="my-4">
                        <h5> {{ $t('tier_admin.suplements') }}:</h5>
                        <span v-for="supplement, index in user.licences.main_licence.supplements" :key="index"> {{ supplement.supplement_label }} {{ $t('tier_admin.'+supplement.supplement_type) }}<span v-if="index != user.licences.main_licence.supplements.length - 1"> // </span></span>
                    </div>
                    <div class="my-4 row mx-1">
                        <h5> {{ $t('tier_admin.nombre_chevaux') }}:</h5>
                        <span class="ml-2 mt-n1">{{ user.horses }}</span>
                    </div>
                    <div class="my-4 row mx-1">
                        <h5> {{ $t('tier_admin.nombre_compta') }}:</h5>
                        <span class="ml-2 mt-n1">{{ user.accounting }}</span>
                    </div>
                    <div class="my-4 row mx-1">
                        <h5> {{ $t('tier_admin.nombre_licence') }}:</h5>
                        <span class="ml-2 mt-n1">{{ user.secondary_users.length }}</span>
                    </div>
                </div>
            </div>
            <div >   
                <div >
                    <div>
                        <h2 class="ml-2">{{ $t('tier_admin.utilisateur_secondaire') }}:</h2>
                    </div>
                    <div v-if="!user_loading">
                        <div v-for="secondary_user,index in user.secondary_users" :key="index" class="my-4">
                            <div class="box" v-if="secondary_user.id != user.licence_id">
                                <h3>{{ secondary_user.email }}</h3>
                                <div >
                                    <h5>{{ $t('tier_admin.droit') }}: </h5>
                                    <span v-for="droit, index in secondary_user.rights" :key="index"> {{ droit.label }} <span v-if="index !=  secondary_user.rights.length - 1"> // </span></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-4">   
                <div>
                    <h2 class="ml-2">{{ $t('tier_admin.contacts') }} ({{ contacts_users.length }}):</h2>
                </div>
                <div v-if="!contact_loading">
                    <div v-for="contact,index in contacts_users" :key="index" class="box">
                        <div class="row d-flex justify-content-between mx-2">
                            <div>
                                <h3>{{ contact.contact.contact_mail }}</h3>
                                <h6>{{ $t('tier_admin.date_validite') }}: {{ contact.user.licence_date_of_validity }}</h6>
                            </div>
                            <button class="btn btn-primary" type="button" @click.prevent="launchAutoLog(contact.contact.contact_mail)">{{ $t('global.autolog_connect') }}</button>
                        </div>
                        <div>
                            <div class="my-4">
                                <h5> {{ $t('tier_admin.gamme') }}:</h5>
                                <span> {{ contact.licences.main_licence.gamme.gamme_label }}</span>
                            </div>
                            <div class="my-4">
                                <h5> {{ $t('tier_admin.options') }}:</h5>
                                <!-- {{ contact.licences.main_licence.options }} -->
                                <span v-for="option, index in contact.licences.main_licence.options" :key="index"> {{ option.option_label }} <span v-if="index != contact.licences.main_licence.options.length - 1"> // </span></span>
                            </div>
                            <div class="my-4">
                                <h5> {{ $t('tier_admin.suplements') }}:</h5>
                                <span v-for="supplement, index in contact.licences.main_licence.supplements" :key="index"> {{ supplement.supplement_label }} {{ $t('tier_admin.'+supplement.supplement_type) }}<span v-if="index != contact.licences.main_licence.supplements.length - 1"> // </span></span>
                            </div>
                            <div class="my-4 row mx-1">
                                <h5> {{ $t('tier_admin.nombre_chevaux') }}:</h5>
                                <span class="ml-2 mt-n1">{{ contact.horses }}</span>
                            </div>
                            <div class="my-4 row mx-1">
                                <h5> {{ $t('tier_admin.nombre_compta') }}:</h5>
                                <span class="ml-2 mt-n1">{{ contact.accounting }}</span>
                            </div>
                            <div class="my-4 row mx-1">
                                <h5> {{ $t('tier_admin.nombre_licence') }}:</h5>
                                <span class="ml-2 mt-n1">{{ contact.secondary_users.length}}</span>
                            </div>
                        </div> 
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-else>
        {{ $t('tier_admin.tier_no_user') }}
    </div>
</template>

<script type="text/javascript">
	import Navigation from "@/mixins/Navigation.js"
    import Tiers from "@/mixins/Tiers.js"
    import _defaultsDeep from 'lodash/defaultsDeep'
    import Accounting from "@/mixins/Accounting.js"

	export default {
		name: "tiersAdmin",
		props: ['tiers_id'],
		mixins: [Navigation, Tiers, Accounting],
		data () {
			return {
                is_user: false,
				tiers: {},
				contacts: [],
                user: [],
                licences: [],
                licence: {},
                licence_number:0,
                accounting: 0,
                horses: 0,
                contacts_users : [],
                loading: false,
                spinner: false,
                contact_loading : true,
                user_loading : true,
                users:{},
			}
		},
		mounted() {
			this.init_component()
		},
		methods: {
			async init_component() {
                this.tiers = await this.loadTier(this.tiers_id)
                this.contacts = await this.loadTiersContacts(this.tiers_id)
                this.user = await this.getUserByMail(this.tiers.tiers_mail)

                if(this.user.length > 0) {       
                    this.is_user = true
                    this.user_loading = true
                    this.user = this.user[0]
                    this.user.licences = await this.getUserData(this.user)
                    this.user.horses = await this.getUserHorses(this.user.licence_key, this.user.licence_id)
                    this.user.accounting = await this.getUserAccounting(this.user.licence_key, this.user.licence_id)
                    this.user.secondary_users = await this.getSecondaryUsers(this.user.licence_id)
                    this.user_loading = false
                }
                if (this.contacts){
                    this.formatContact()
                }
			},
            async getUserData(user) {
                const data = await this.getUserGamme(user.email)                
                let retour = data.retour || null
                if(!retour) throw 'No available result'
                // Pour la licence principale, on va chercher les éventuelles infos d'espace client
                const main_licence = Object.keys(retour)[0]
                let number = 0
                Object.keys(retour).forEach(licence_key => {
                    retour[licence_key].gamme = retour[licence_key].gamme[0]
                    number++
                })
                const licences = retour
                let licence = {}
                if(licences && licences[main_licence]){
                    licence = licences[main_licence]
                }
                return {'licences':number, 'main_licence': licence}
		    },
            async getUserHorses(licence_key, licence_id) {
                let horses = await this.getValidHorsesByUser(licence_key,  licence_id)
                return horses.length
		    },
            async getUserAccounting(licence_key, licence_id) {
                let accounting = await this.loadAccountingPlansByUser(licence_key,  licence_id)
                return accounting.length
		    },
            async getUserByMail(mail) {
                return await this.getUserByTierMail(mail)
		    },
            async formatContact() {
                this.contact_loading = true
                for (var i = 0; i < this.contacts.length; i++) {
                    this.contacts[i].user = await this.getUserByMail(this.contacts[i].contact.contact_mail)
                    if (this.contacts[i].user && this.contacts[i].user.length > 0){
                        this.contacts[i].user = this.contacts[i].user[0]
                        this.contacts[i].licences = await this.getUserData(this.contacts[i].user)
                        this.contacts[i].horses = await this.getUserHorses(this.contacts[i].user.licence_key, this.contacts[i].user.licence_id)
                        this.contacts[i].accounting = await this.getUserAccounting(this.contacts[i].user.licence_key,this.contacts[i].user.licence_id)
                        this.contacts[i].secondary_users = await this.getSecondaryUsers(this.contacts[i].user.licence_id)
                        this.contacts_users.push(this.contacts[i])
                    }
                }
                this.contact_loading = false
		    },
            async launchAutoLog(email = null) {
				if(email) {
					await this.fetchUser(email)
				}

                // On delog complètement la session actuelle
                await this.delogUser()

                // On set la licence + bearer
                await this.persistUserSession(this.user.token, this.user.licence_key, this.user.licence_username, this.user.user_id, this.user.valid_user, this.user.lang)

                // On relance l'appli qui chargera les données user
                window.location.href = "/"
            },
            fetchUser(email) {
                this.loading = true
                this.spinner = true
                this.user = {}

                return this.autologUser(email).then((result)=>{
                    if(result.code_retour !== 0) throw "AutoLogAdmin::fetchUser => bad code response:" + result.code_retour
                    this.user = result.retour
                }).catch((e)=>{
                    this.failureToast("error.IMA")
                    console.error(e)
                }).finally(()=>{
                    this.spinner = false
                    this.loading = false
                })
            },
            async getSecondaryUsers($licence_id) {
				const users = this.getUsersToManage($licence_id)
					.then(res => {
						const temp = res.retour

						temp.forEach(user => {
							user.changeMail = false
							user.oldMail = user.email
							user.rights = []

							user.actual_rights.forEach(module_code => {
								user.rights.push({
									code: module_code,
									label: this.getTrad('types.modules_code.'+module_code)
								})
							})
						})

						return temp
					})
                return users
			},
        },
        watch: {
          
        },
		components: {
		
		}
	}
</script>